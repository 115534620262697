import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { orange, green, red, blue } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';

const styles = theme => ({
    icon: {
        fontSize: 45,
    },
    iconVariant: {
        opacity: 0.9,
        marginBottom: theme.spacing(1),
    },
});

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const variantIconColor = {
    success: green[500],
    warning: orange[500],
    error: red[500],
    info: blue[500],
};

const EmptyStateLabel = ({ ...props }) => {
    const { text, icon, classes, retryAction, style } = props;


    const Icon = icon ? variantIcon[icon] : null;
    const iconColor = icon ? variantIconColor[icon] : null;

    return (
        <div style={{ flex: '1 1 auto', padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', ...style }}>
            {Icon &&
                <Icon className={clsx(classes.icon, classes.iconVariant)} style={{ color: iconColor }} />
            }
            <Typography variant="subtitle1" align="center">{text}</Typography>
            {retryAction &&
                <div style={{ marginTop: 16 }}>
                    <Button variant="contained" color="primary" onClick={() => retryAction()}>Reintentar</Button>
                </div>
            }
        </div>
    )
}

EmptyStateLabel.defaultProps = {
    icon: "info",
}

EmptyStateLabel.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.node.isRequired,
    icon: PropTypes.oneOf(["info", "error", "warning", "success"]),
};

export default withStyles(styles)(EmptyStateLabel);