import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Material UI Styles
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  progress: {
    flex: '1 0 auto'
  }
});

const defaultMsg = <>
  Espera un momento por favor...
</>

class LoadingPanel extends Component {
  render() {
    let { classes, msg } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%', padding: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress className={classes.progress} thickness={6} />
          <div style={{ marginLeft: 18 }}>
            <Typography variant="subtitle1">
              {!isEmpty(msg) ? msg : defaultMsg}
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

LoadingPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  msg: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(LoadingPanel);