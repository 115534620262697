import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { TextField } from "components";
import BaseNumberFormat from "components/BaseNumberFormat";

const NumberFormatRegular = (props) => {
  return (
    <BaseNumberFormat
      allowNegative={false}
      {...props}
    />
  )
}

const LocalNumberField = (props) => {
  const { formater, ...other } = props;
  return (
    <TextField
      {...other}
      InputProps={{
        inputComponent: NumberFormatRegular,
        inputProps: { ...formater },
      }} />
  )
}

const NumberField = ({ innerDiv, ...rest }) => (
  <Fragment>
    {innerDiv ?
      <div className="mb-3">
        <LocalNumberField {...rest} />
      </div>
      :
      <LocalNumberField {...rest} />
    }
  </Fragment>
)

NumberField.propTypes = {
  formater: PropTypes.object,
};

NumberField.defaultProps = {
  innerDiv: true,
};

export default NumberField;