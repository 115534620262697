import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import has from 'lodash/has';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber'
import Button from '@material-ui/core/Button';
import MaterialSnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

// import Delayed from 'react-delayed';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  success: {
    backgroundColor: green[600],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Snackbar = ({ ...props }) => {
  const { notificacion, classes, className } = props;
  const [open, setOpen] = useState(!isEmpty(notificacion))
  const Icon = notificacion && notificacion.tipo ? variantIcon[notificacion.tipo] : null;
  const variant = notificacion && notificacion.tipo ? notificacion.tipo : null;

  // const renders = useRef(0);

  // useEffect(() => {
  //     console.log(notificacion)
  // }, [notificacion]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleExited = () => {
    if (!isEmpty(notificacion)) {
      props.onDismiss(notificacion.id);
    }
  };

  const checkAutoHide = () => {
    const autoHide = has(notificacion, 'autoHide') ? notificacion.autoHide : true;
    return autoHide === true ? 6000 : null;
  }

  const actionButtons = () => {
    const closeButton = <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
      <CloseIcon className={classes.icon} />
    </IconButton>
    let actions = [];
    if (notificacion.actions && isArray(notificacion.actions)) {
      notificacion.actions.forEach(action => {
        if (isObject(action) && action.callback && action.text && action.key) {
          actions.push(
            <Button key={action.key} color={action.color || "secondary"} size="small" onClick={() => {
              action.callback();
              handleClose();
            }}>{action.text}</Button>
          )
        }
      });
    }
    actions.push(closeButton);
    return actions;
  }

  return (
    //El delay le da un mejor efecto al presentarse...
    // <Delayed mounted={true} mountAfter={500} >
    <Fragment>
      {/* {console.log("Snackbar", renders.current++)} */}
      <MaterialSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        disableWindowBlurListener
        autoHideDuration={checkAutoHide()}
        onClose={handleClose}
        onExited={handleExited}>
        <SnackbarContent
          className={clsx(classes[variant], className)}
          contentprops={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id" className={classes.message}>
              {Icon &&
                <Icon className={clsx(classes.icon, classes.iconVariant)} />
              }
              {notificacion.mensaje}
            </span>
          }
          action={actionButtons()} />
      </MaterialSnackbar>
    </Fragment>
    // </Delayed>
  );

}

Snackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  onDismiss: PropTypes.func,
};

export default React.memo(withStyles(styles)(Snackbar));