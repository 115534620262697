import React, { useState, useEffect, Fragment, useContext } from 'react';
import { AppContext } from 'components/AppDesktop';

import { useApi, obtenerCuentas } from 'api';
import isArray from 'lodash/isArray';
import { SuspenseLoading } from 'Routes';

const cuentaInitialState = {
  init: false,
  loaded: false,
  logged: false,
  permisos: [],
};

const cuentaContextInitialState = {
  cuenta: {},
  actualizaCuenta: () => { },
  tieneCuentaPermisos: () => { },
  wipeAllData: () => { },
  recargaCuenta: () => { },
};

export const CuentaContext = React.createContext(cuentaContextInitialState);

export const useCuenta = () => {
  const contextState = React.useContext(CuentaContext);
  if (contextState === null) {
    throw new Error('useCuenta must be used within a CuentaProvider tag');
  }
  return contextState;
};

const CuentaProvider = ({ children, ...props }) => {

  const { app, actualizaApp } = useContext(AppContext);
  const [cuenta, setCuenta] = useState(cuentaInitialState);

  const [{ data: cuentaData, loading: loadingCuenta, error: cuentaError }, cuentaApi] = useApi(null, { defaultError: false });

  useEffect(() => {
    if (app.needUpdateSession === true) { //Se venció la sesion... a firmarse de nuevo
      setCuenta(cuentaInitialState);
      actualizaApp({ needUpdateSession: false });
    }
  }, [app]);

  useEffect(() => {
    if (cuenta.init !== true && cuenta.loaded !== true && loadingCuenta !== true) {
      cuentaApi(obtenerCuentas());
    }
  }, [cuenta]);

  useEffect(() => {
    if (cuentaError) {
      //cuentaError.response.status === 401 //Se hace algo??
      setCuenta({
        ...cuenta,
        init: true
      });
    }
  }, [cuentaError]);

  useEffect(() => {
    var _cuenta = {
      ...cuenta
    }
    if (cuentaData) {
      _cuenta = {
        ..._cuenta,
        init: true,
      }
      if (cuentaData.length > 0) {
        _cuenta = {
          ..._cuenta,
          ...cuentaData[0],
          loaded: true,
          logged: true
        }
      }
      setCuenta({
        ..._cuenta,
      })
    }
  }, [cuentaData]);

  const recargaCuenta = React.useCallback(async () => {
    await cuentaApi(obtenerCuentas());
  }, []);

  const actualizaCuenta = React.useCallback((value) => {
    if (value === undefined) {
      setCuenta(cuentaInitialState);
    } else {
      setCuenta({
        ...cuenta,
        ...value,
      });
    }
  }, []);

  const tieneCuentaPermisos = React.useCallback((permisos = []) => {
    if (!isArray(permisos)) {
      permisos = [permisos];
    }
    if (permisos.length > 0 && cuenta && cuenta.permisos && cuenta.permisos.length > 0) {
      const cuentaPermisos = cuenta.permisos.filter(p => permisos.includes(p.codigo));
      return cuentaPermisos.length > 0;
    }
    return false;
  }, []);

  const wipeAllData = React.useCallback(() => {
    actualizaCuenta();
    actualizaApp();
  }, []);

  return (
    <Fragment>
      <CuentaContext.Provider value={{
        cuenta,
        actualizaCuenta,
        tieneCuentaPermisos,
        wipeAllData,
        recargaCuenta,
      }}>
        <Fragment>
          {children}
          {loadingCuenta &&
            <SuspenseLoading />
          }
        </Fragment>
      </CuentaContext.Provider>
    </Fragment>
  )
}

export default CuentaProvider;