import Qs from 'qs';
import { trimObj } from 'components/Utils';

export const obtenerCuentas = () => ({
  url: `usuarios`,
  method: 'GET',
})

export const login = (username, password, rememberme = false) => ({
  url: 'login',
  method: 'POST',
  data: Qs.stringify({
    'username': username,
    'password': password,
    'remember_me': rememberme
  }),
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
});

export const logout = () => ({
  url: 'logout',
  method: 'POST',
})

export const consultarCuenta = (id) => ({
  url: `usuarios/${id}`,
  method: 'GET',
})

export const restablecerPassword = (data) => ({
  url: 'usuarios/password',
  method: 'POST',
  data: trimObj(data)
});

export const registroCuenta = (data) => ({
  url: 'usuarios/registros',
  method: 'POST',
  data: trimObj(data)
});

export const activarCuenta = (data) => ({
  url: 'usuarios/confirmaciones',
  method: 'POST',
  data: trimObj(data)
});

export const cambiarPassword = (usuarioId, data) => ({
  url: `usuarios/${usuarioId}/password`,
  method: 'POST',
  data: trimObj(data)
});

export const solicitaCambiarEmail = (usuarioId, data) => ({
  url: `usuarios/${usuarioId}/email`,
  method: 'POST',
  data: trimObj(data)
});

export const confirmarEmail = (data) => ({
  url: `usuarios/email`,
  method: 'POST',
  data: trimObj(data)
});

export const actualizarCuenta = (data, usuarioId) => ({
  url: `usuarios/${usuarioId}`,
  method: 'PUT',
  data: trimObj(data)
})