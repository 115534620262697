var iconsMap = {
  HomeIcon: ['fas', 'home'],
  ProductsIcon: ['fas', 'cubes'],
  EdoCuentaIcon: ['fas', 'file-invoice-dollar'],
  CobrosElectronicosIcon: ['fas', 'money-check-alt'],
  SoporteIcon: ['far', 'life-ring'],
  AjustesIcon: ['fas', 'cog'],
};

export default [
  {
    label: 'Navigation menu',
    content: JSON.parse(
      `[
  {
    "label": "Principal",
    "fai": "HomeIcon",
    "to": "/dashboard"
  },
  {
    "label": "Productos",
    "fai" : "ProductsIcon",
    "to": "/productos"
  },
  {
    "label": "Estado de cuenta",
    "fai": "EdoCuentaIcon",
    "to": "/edocuenta"
  },
  {
    "label": "Cobros electrónicos",
    "fai": "CobrosElectronicosIcon",
    "to": "/cobroselectronicos"
  },
  {
    "label": "Soporte",
    "fai": "SoporteIcon",
    "to": ["/tickets"],
    "content": [
      {
        "label": "Tickets",
        "description": "This is a dashboard page example built using this template.",
        "to": "/tickets"
      }
    ]
  },
  {
    "label": "Ajustes",
    "fai": "AjustesIcon",
    "to": ["/micuenta", "/organizacion"],
    "content": [
      {
        "label": "Mi cuenta",
        "description": "Wide selection of buttons that feature different styles for backgrounds, borders and hover options!",
        "to": "/micuenta"
      },
      {
        "label": "Organización",
        "description": "A drop-down list is a graphical control element, similar to a list box, that allows the user to choose one value from a list.",
        "to": "/organizacion"
      }
    ]
  }
]`,
      (key, value) => {
        if (key === 'icon' || key === 'fai') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
