import React, { Fragment, useEffect } from 'react';

import PagesLoginContent from './PagesLogin';

export default function PagesLogin() {

  useEffect(() => {
    document.title = 'ESCOLASTIA - Dashboard';
  }, []);

  return (
    <Fragment>
      <PagesLoginContent />
    </Fragment>
  );
}
