import React, { Fragment } from "react";
import { TextField } from "components";
import BaseNumberFormat from "components/BaseNumberFormat";

const NumberFormatRegular = (props) => {
  return (
    <BaseNumberFormat
      allowNegative={false}
      format="#####"
      {...props}
    />
  )
}

const LocalCodigoPostalField = (props) => {
  const { formater, ...other } = props;
  return (
    <TextField
      {...other}
      InputProps={{
        inputComponent: NumberFormatRegular,
        inputProps: { ...formater },
      }} />
  )
}

const CodigoPostalField = ({ innerDiv, ...rest }) => (
  <Fragment>
    {innerDiv ?
      <div className="mb-3">
        <LocalCodigoPostalField {...rest} />
      </div>
      :
      <LocalCodigoPostalField {...rest} />
    }
  </Fragment>
)

CodigoPostalField.propTypes = {
};

CodigoPostalField.defaultProps = {
  innerDiv: true,
};

export default CodigoPostalField;