import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Box } from '@material-ui/core';

import { connect } from 'react-redux';

import projectLogo from '../../assets/images/escolastia/logo-letras-blanco-small.png';
import projectLogoSmall from '../../assets/images/escolastia/logo-small.png';

const HeaderLogo = props => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box
          className="header-logo-wrapper"
          title="ESCOLASTIA - Administrador">
          <Link to="/dashboard" className="header-logo-wrapper-link">
            <img
              className={clsx({
                "app-header-logo-img": !sidebarToggle,
                "app-header-logo-small-img": sidebarToggle
              })}
              alt="ESCOLASTIA - Administrador"
              src={sidebarToggle ? projectLogoSmall : projectLogo}
            />
          </Link>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
