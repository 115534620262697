import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { TextField } from "components";
import BaseNumberFormat from "components/BaseNumberFormat";

const NumberFormatRegular = (props) => {
  const { amex, ...others } = props;
  const format = amex === true ? "####" : "###";
  return (
    <BaseNumberFormat
      allowNegative={false}
      format={format}
      {...others}
    />
  )
}

const LocalCVVField = (props) => {
  const { amex, InputProps, ...other } = props
  const creditCardProps = {
    amex: props.amex,
  }
  return (
    <TextField
      {...other}
      InputProps={{
        ...InputProps,
        inputProps: creditCardProps,
        inputComponent: NumberFormatRegular,
      }} />
  )
}

const CVVField = ({ innerDiv, ...rest }) => (
  <Fragment>
    {innerDiv ?
      <div className="mb-3">
        <LocalCVVField {...rest} />
      </div>
      :
      <LocalCVVField {...rest} />
    }
  </Fragment>
)

CVVField.propTypes = {
  amex: PropTypes.bool,
};

CVVField.defaultProps = {
  innerDiv: true,
  amex: false
};

export default CVVField;