import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { grey, deepOrange } from '@material-ui/core/colors';

const styles = theme => ({
    main: {

    }
});

const IndicatorLabel = ({ title, subtitle, value, style, ...props }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 8, borderBottom: `1px solid ${grey[300]}`, ...style }}>
            <div>
                <Typography style={{ fontWeight: 500 }} color="primary">{title}</Typography>
                <Typography style={{ color: grey[500] }} variant="caption">{subtitle}</Typography>
            </div>
            <Typography component="div" variant="h5" style={{ color: deepOrange[500], fontWeight: 500 }}>{value}</Typography>
        </div>
    )
}

export default withStyles(styles)(IndicatorLabel);