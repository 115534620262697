import Qs from 'qs';
import { trimObj } from 'components/Utils';

export const sitenameCheck = (sitename) => ({
  url: `clientes/sitenamecheck`,
  method: 'GET',
  params: {
    sitename
  }
});

export const asociaCliente = (data) => ({
  url: `clientes`,
  method: 'POST',
  data: trimObj(data)
})

export const crearInstancia = (clienteId) => ({
  url: `instancias`,
  method: 'POST',
  params: {
    clienteId
  }
});

export const consultarInstancia = (instanciaId) => ({
  url: `instancias/${instanciaId}`,
  method: 'GET'
});

export const consultarCliente = (clienteId) => ({
  url: `clientes/${clienteId}`,
  method: 'GET'
});

export const actualizaCliente = (data, clienteId) => ({
  url: `clientes/${clienteId}`,
  method: 'PUT',
  data: trimObj(data)
})

//DATOS FISCALES

export const consultarDatosFiscales = (clienteId) => ({
  url: `clientes/${clienteId}/datosfiscales`,
  method: 'GET'
});

export const consultarDatoFiscal = (datoFiscalId, clienteId) => ({
  url: `clientes/${clienteId}/datosfiscales/${datoFiscalId}`,
  method: 'GET'
});

export const agregarDatoFiscal = (data, clienteId) => ({
  url: `clientes/${clienteId}/datosfiscales`,
  method: 'POST',
  data: trimObj(data)
});

export const actualizarDatoFiscal = (data, datoFiscalId, clienteId) => ({
  url: `clientes/${clienteId}/datosfiscales/${datoFiscalId}`,
  method: 'PUT',
  data: trimObj(data)
})

export const establecePrincipalDatoFiscal = (datoFiscalId, clienteId) => ({
  url: `clientes/${clienteId}/datosfiscales/${datoFiscalId}`,
  method: 'PUT',
  params: {
    principal: true
  }
})


//PRODUCTO y MÓDULOS

export const consultarProductos = (clienteId) => ({
  url: `clientes/${clienteId}/productos`,
  method: 'GET'
});


export const consultarModulosDisponibles = (clienteId, productId) => ({
  url: `clientes/${clienteId}/productos/${productId}/modulos`,
  method: 'GET',
  params: {
    disponibles: true
  }
});

export const agregarModuloACliente = (data, clienteId, productId) => ({
  url: `clientes/${clienteId}/productos/${productId}/modulos`,
  method: 'POST',
  data: trimObj(data)
});

export const solicitaDesactivarModuloCobrosElectronicos = (clienteId, productId) => ({
  url: `clientes/${clienteId}/productos/${productId}/modulos/cobroselectronicos`,
  method: 'PATCH',
});

// CUENTA BANCARIA

export const agregarCuentaBancaria = (data, clienteId) => ({
  url: `clientes/${clienteId}/cuentasbancarias`,
  method: 'POST',
  data: trimObj(data)
});

//FORMAS DE PAGO

export const consultarFormasPago = (clienteId, vigentes = false) => ({
  url: `clientes/${clienteId}/formaspago`,
  method: 'GET',
  params: {
    vigentes
  }
});

export const agregarFormaPago = (data, clienteId) => ({
  url: `clientes/${clienteId}/formaspago`,
  method: 'POST',
  data: trimObj(data)
});

export const actualizarFormaPago = (data, formaPagoId, clienteId) => ({
  url: `clientes/${clienteId}/formaspago/${formaPagoId}`,
  method: 'PUT',
  data: trimObj(data)
})

export const eliminarFormaPago = (formaPagoId, clienteId) => ({
  url: `clientes/${clienteId}/formaspago/${formaPagoId}`,
  method: 'DELETE',
})

// CUENTAS POR PAGAR

export const consultarCuentasPagar = (clienteId, desde, hasta) => ({
  url: `clientes/${clienteId}/cuentaspagar`,
  method: 'GET',
  params: {
    desde,
    hasta,
  }
});

export const consultarAdeudos = (clienteId) => ({
  url: `clientes/${clienteId}/cuentaspagar`,
  method: 'GET',
  params: {
    adeudos: true,
  }
});

export const consultarCuentaPagar = (clienteId, cuentaPagarId) => ({
  url: `clientes/${clienteId}/cuentaspagar/${cuentaPagarId}`,
  method: 'GET'
});

// PAGOS

export const registrarTransaccionPago = (data, clienteId, cuentaPagarId, medio) => ({
  url: `clientes/${clienteId}/cuentaspagar/${cuentaPagarId}/pagar`,
  method: 'POST',
  data: trimObj(data),
  params: {
    medio
  }
});

export const consultarTransaccionPago = (clienteId, orderId) => ({
  url: `clientes/${clienteId}/pagos`,
  method: 'GET',
  params: {
    orderId,
  }
});

// FACTURACION

export const facturarTransaccion = (data, clienteId, cuentaPagarId) => ({
  url: `clientes/${clienteId}/cuentaspagar/${cuentaPagarId}/facturar`,
  method: 'POST',
  data: trimObj(data),
});

// indicadores

export const indicadoresPrincipales = (clienteId, ciclosId) => ({
  url: `clientes/${clienteId}/indicadores`,
  method: 'GET',
  params: {
    ciclosId,
  },
  paramsSerializer: (params) => {
    return Qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

export const ciclosIndicadoresPrincipales = (clienteId) => ({
  url: `clientes/${clienteId}/ciclos`,
  method: 'GET',
})