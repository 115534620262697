import React, { Fragment } from "react";
import PropTypes from "prop-types";
import isUndefined from 'lodash/isUndefined';

import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";

import isEmpty from "lodash/isEmpty";
import clsx from "clsx";

const LocalComboSelect = ({ id, field, errors, options, label, emptyValue, emptyLabel, margin, color, size, fullWidth, required, className, style, selectstyle, variant, placeholder, ...others }) => {

  const calculateHelperText = () => {
    let helperText = errors && errors.hasOwnProperty(id || field) ? errors[id || field] : null;

    if ((helperText === '' || isUndefined(helperText)) && !isUndefined(placeholder)) {
      helperText = placeholder;
    }
    return helperText;
  }

  return (
    <FormControl
      variant={variant}
      required={required}
      fullWidth={fullWidth}
      margin={margin}
      className={className}
      style={{ ...style }}
      size={size}
      error={errors ? !isEmpty(errors[id || field]) : false}>
      {!isEmpty(label) &&
        <InputLabel htmlFor={id || field}>{label}</InputLabel>
      }
      <Select
        {...others}
        label={label}
        style={{ ...selectstyle }}
        inputProps={{
          name: id || field,
          id: id,
        }}>
        {others.value === emptyValue && !isEmpty(emptyValue) &&
          <MenuItem value={emptyValue} disabled>
            <em style={{ color: '#BDBDBD' }}>{emptyLabel ? emptyLabel : "Seleccione..."}</em>
          </MenuItem>
        }
        {options.map(record => {
          return (
            <MenuItem key={record.value} value={record.value} style={{ height: 'auto' }}>
              {record.text}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>{calculateHelperText()}</FormHelperText>
    </FormControl>
  )
};

const ComboSelect = ({ innerDiv, innerDivClassName, ...rest }) => (
  <Fragment>
    {innerDiv ?
      <div className={clsx("mb-3", innerDivClassName)}>
        <LocalComboSelect {...rest} />
      </div>
      :
      <LocalComboSelect {...rest} />
    }
  </Fragment>
)

ComboSelect.defaultProps = {
  margin: 'none',
  required: false,
  color: 'secondary',
  size: null, //'small',
  fullWidth: false,
  variant: 'outlined',
  selectstyle: null,
  innerDiv: true,
};

ComboSelect.propTypes = {
  label: PropTypes.string,
  // id: PropTypes.string.isRequired,
  //field: PropTypes.string.isRequired,
  required: PropTypes.bool,
  innerDiv: PropTypes.bool,
  innerDivClassName: PropTypes.object
};

export default ComboSelect;