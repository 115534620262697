import React from 'react';
import Axios from 'axios';
import LRU from 'lru-cache';
import isNil from 'lodash/isNil';
import { AppContext } from '../components/AppDesktop';
import FileDownload from 'js-file-download';

const actions = {
  DOWNLOAD_DATA: 'DOWNLOAD_DATA',
  REQUEST_START: 'REQUEST_START',
  REQUEST_END: 'REQUEST_END'
}

const ssrPromises = []

const isProd = process.env.NODE_ENV === 'production';

//****NO MOVER ****/
let SERVER_API_PROTOCOL = window.location.protocol;
let SERVER_API_HOST = window.location.host; //process.env.REACT_APP_SERVER_API_HOST || 'playground.escolastia.com';
let SERVER_API_PORT;
//****NO MOVER ****/

if (!isProd) {
  SERVER_API_PROTOCOL = 'http:'
  SERVER_API_HOST = process.env.REACT_APP_SERVER_API_HOST || 'localhost'
  SERVER_API_PORT = process.env.REACT_APP_SERVER_API_PORT || '8083';
}

export const API_BASE_URL = `${SERVER_API_PROTOCOL ? SERVER_API_PROTOCOL + '//' : ''}${SERVER_API_HOST}${SERVER_API_PORT ? ':' + SERVER_API_PORT : ''}/api`

let cache = new LRU()
let axiosInstance = Axios.create({
  baseURL: API_BASE_URL,
  // headers: {
  // 'X-Api-Version': '0.0.1'
  // },
  withCredentials: true,
}) //DefaultAxios

export function configure(options) {
  if (options.axios) {
    axiosInstance = options.axios
  }

  if (options.cache) {
    cache = options.cache
  }
}

export function loadCache(data) {
  cache.load(data)
}

export async function serializeCache() {
  await Promise.all(ssrPromises)

  ssrPromises.length = 0

  return cache.dump()
}

async function cacheAdapter(config) {
  const cacheKey = JSON.stringify(config)
  const hit = cache.get(cacheKey)

  if (hit) {
    return hit
  }

  delete config.adapter

  const response = await axiosInstance(config)

  const responseForCache = { ...response }
  delete responseForCache.config
  delete responseForCache.request

  cache.set(cacheKey, responseForCache)

  return response
}

function createInitialState(options) {
  return {
    loading: !options.manual
  }
}

function reducer(state, action) {
  // console.log(state, action);
  switch (action.type) {
    case actions.DOWNLOAD_DATA:
      return {
        ...state,
        downloadData: action.downloadData
      }
    case actions.REQUEST_START:
      return {
        // ...state,
        downloadData: state.downloadData,
        cancel: action.cancel,
        loading: true
      }
    case actions.REQUEST_END:
      return {
        ...state,
        loading: false,
        ...(action.error ? {} : { data: action.payload.data }),
        [action.error ? 'error' : 'response']: action.payload
      }
    default:
      return state
  }
}

const processError = async (error, actualizaApp) => { //TODO Mejorar
  if (error && error.response && error.response.status === 401) {
    //Se venció la sesión
    console.log("Se venció la sesión, autenticate de nuevo...");
    actualizaApp({ needUpdateSession: true });
    return;
  }
  if (error) {
    // const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";

    if (error.response && error.response.status === 403) {
      return "No cuentas con privilegios para esta operación";
    } if (error.response && error.response.status === 500) {
      return "Servicio no disponible, intente nuevamente";
    }

    // let msg = "Servicio no disponible, intente nuevamente";
    if (error.response && error.response.data) {
      const errorJson = await parseJsonFromBlob(error.response);
      return errorJson.error ? errorJson.error : "Servicio no disponible, intente nuevamente";
    } else {
      return "Servicio no disponible, intente nuevamente";
    }
    // if (error.response && error.response.data && isJsonBlob(error.response.data)) {
    //     parseJsonFromBlob(error.response);
    // }

    // return msg;
  }
  return;
}

const parseJsonFromBlob = async (response) => {
  const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
  const responseData = isJsonBlob(response.data) ? await (response.data).text() : response.data || {};
  const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
  console.log(responseJson)
  return responseJson;
}

async function request(config, dispatch) {
  try {
    const source = Axios.CancelToken.source();
    dispatch({ type: actions.REQUEST_START, cancel: source })
    const response = await axiosInstance({
      ...config,
      cancelToken: source.token
    })
    dispatch({ type: actions.REQUEST_END, payload: response })
  } catch (err) {
    if (Axios.isCancel(err)) {
      console.log(`Call for "${config.url}" was cancelled`);
    } else {
      dispatch({ type: actions.REQUEST_END, payload: err, error: true })
    }
  }
}

function executeRequestWithCache(config, dispatch) {
  request({ ...config, adapter: cacheAdapter }, dispatch)
}

function executeRequestWithoutCache(config, dispatch) {
  return request(config, dispatch)
}

// export const downloadFile = async (url, success, error, extraConfig, axiosNewInstance = false) => {
//     const source = Axios.CancelToken.source();
//     const instance = axiosNewInstance === true ? Axios.create() : axiosInstance;
//     const config = {
//         ...extraConfig,
//         responseType: 'blob',
//         url,
//     }
//     try {
//         const response = await instance({
//             ...config,
//             cancelToken: source.token
//         })
//         success(response);
//     } catch (err) {
//         if (Axios.isCancel(err)) {
//             console.log(`Call for "${config.url}" was cancelled`);
//         } else {
//             error(err);
//         }
//     }
//     return source;
// }

export const handleErrorAPI = (error, appContext) => {
  const { notificarError, actualizaApp } = appContext;
  if (error) {
    processError(error, actualizaApp)
      .then(msg => notificarError(msg));
  }
  //notificarError(processError(error, actualizaApp));
}

export function useDownloadFile(appContext) {
  const { showLoadingDialog } = appContext;
  const [state, override] = useApi();

  React.useEffect(() => {
    if (state.response && state.response.status === 200 && state.response.data) {
      if (state.downloadData && !isNil(state.downloadData.fileName)) {
        FileDownload(state.response.data, state.downloadData.fileName);
      }
    }
    showLoadingDialog(state.loading);
  }, [state]);

  return [
    state,
    (url, fileName, config) => {
      if (isNil(fileName)) {
        console.warn("DownloadFile : No proporcionaste el nombre del archivo, debes manejar el data del estado.")
      }
      override({
        url,
        ...config,
        responseType: 'blob',
        method: 'GET',
        downloadData: {
          fileName
        }
      })
    }
  ]
}

export function useApi(config, options = { manual: false, defaultError: true }) {
  const appContext = React.useContext(AppContext);

  if (!config) {
    options.manual = true;
  }

  const [state, dispatch] = React.useReducer(
    reducer,
    createInitialState(options)
  )

  if (typeof window === 'undefined') {
    ssrPromises.push(axiosInstance({ ...config, adapter: cacheAdapter }))
  }

  React.useEffect(() => {
    if (!options.manual) {
      executeRequestWithCache(config, dispatch)
    }
  }, [JSON.stringify(config)])

  React.useEffect(() => {
    if (options.defaultError) {
      handleErrorAPI(state.error, appContext);
    }
  }, [state]);

  return [
    state,
    configOverride => {
      if (configOverride.downloadData) { //Cuando se pasan valores para ls descarga de archivo, p.e. fileName
        dispatch({ type: actions.DOWNLOAD_DATA, downloadData: configOverride.downloadData });
      }
      return executeRequestWithoutCache(configOverride, dispatch)
    }
  ]
}

//For cancel....
const CancelToken = Axios.CancelToken;

export const withCancel = (cancelFn, config) => {
  return {
    ...config,
    cancelToken: new CancelToken(function executor(cancel) {
      // An executor function receives a cancel function as a parameter
      cancelFn(cancel);
    })
  }
}

export const isCancelRequest = (error) => {
  return Axios.isCancel(error);
}

export const withCancelFoo = (config) => {
  const source = Axios.CancelToken.source();
  return [source, {
    ...config,
    cancelToken: source.token
  }]
}