import React, { Fragment } from 'react';

import {
  Grid,
  Container,
  Tooltip,
  Fab,
  Link,
  withWidth,
  isWidthDown,
} from '@material-ui/core';

import logoLogin from '../../assets/images/escolastia/logo-letras-negro-medium.png';

// import { NavLink as RouterLink } from 'react-router-dom';
import LoginCard from './LoginCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCuenta } from 'components/CuentaProvider';
import { Redirect } from 'react-router/cjs/react-router.min';

const PagesLogin = ({ ...props }) => {
  const { cuenta } = useCuenta();



  return (
    <Fragment>
      {cuenta.init === true && cuenta.loaded === true && cuenta.logged === true &&
        <Redirect to="/" />
      }
      <div className="app-wrapper min-vh-100">
        <div className="app-main flex-column">
          <Tooltip title="Regresar a inicio">
            <Fab
              color="primary"
              className="btn-go-back"
              component={Link}
              href="https://www.escolastia.com">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'arrow-left']} />
              </span>
            </Fab >
          </Tooltip>
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container maxWidth="md">
                    <Grid container spacing={5} className="justify-content-center">
                      <Grid
                        item
                        xs={12}
                        lg={7}
                        className="d-flex flex-column align-items-center">
                        <Container
                          // component={RouterLink}
                          // to="/home"
                          maxWidth="sm"
                          className="d-flex justify-content-center">
                          <img alt="logo escolastia" src={logoLogin} height={isWidthDown('sm', props.width) ? 80 : 100} />
                        </Container>
                        <span className="w-100 text-left text-md-center pb-4">
                          {/* <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                            Login to your account
                          </h1> */}
                          <p className="font-size-lg text-center mb-1 mt-4 text-black-50">
                            Para acceder debes iniciar sesión
                          </p>
                        </span>
                        <LoginCard />
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default withWidth()(PagesLogin);
