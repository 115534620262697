import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';

import moment from 'moment';

export const toComboValues = (records, fieldValue = "id", fieldText = "nombre") => records && records.length > 0 ? records.map(r => ({ value: r[fieldValue], text: r[fieldText] })) : [];

export const toComboValuesRenderer = (records, rendererText, disabled, fieldValue = "id") => records && records.length > 0 ? records.map(r => ({ value: r[fieldValue], text: rendererText && isFunction(rendererText) ? rendererText(r) : '<No renderer function>', disabled: disabled && isFunction(disabled) ? disabled(r) : false })) : [];

export function convertMSToObject(milliseconds) {
  var day, hour, minute, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  return {
    day: day,
    hour: hour,
    minute: minute,
    seconds: seconds
  };
}

export function calculateWeekData(date) {
  const _date = moment(makeJSDateObject(date));
  const week = _date.week();
  const start = _date.startOf('week').toDate();
  const end = _date.endOf('week').toDate();
  return ({
    week,
    start,
    end,
  });
}

export function makeJSDateObject(date) {
  if (isString(date)) {
    return moment(date).toDate();
  }

  if (moment.isMoment(date)) {
    return date.clone().toDate();
  }

  if (date instanceof Date) {
    return new Date(date.getTime());
  }

  return date; // handle case with invalid input
}

export function trimObj(obj) {
  if (isNil(obj)) return obj;
  if (!Array.isArray(obj) && typeof obj != 'object') return obj;
  return Object.keys(obj).reduce(function (acc, key) {
    acc[key.trim()] = typeof obj[key] === 'string' && !isNil(obj[key]) ? obj[key].trim() : trimObj(obj[key]);
    return acc;
  }, Array.isArray(obj) ? [] : {});
}

export const img = (path) => `${process.env.PUBLIC_URL}${path}`;

export function onlyNumbers(value) {
  return value.replace(/[^0-9]/g, '');
}

export function convertToNumber(value) {
  if (isString(value) && !isEmpty(value)) {
    if (value.includes('.')) { //Lo convertimos a un flotante si tiene un punto
      return parseFloat(value.replace(/[^0-9-.]/g, ''));
    } else {
      return parseInt(onlyNumbers(value), 10);
    }
  }
  return value;
}

const _isBlank = (v) => !isNil(v) && v !== '';

export function hasChanged(a, b, props) {
  const _a = pickBy(props ? pick(a, props) : a, _isBlank);
  const _b = pickBy(props ? pick(b, props) : b, _isBlank);

  return JSON.stringify(_a, Object.keys(_a).sort()) !== JSON.stringify(_b, Object.keys(_b).sort());
}

export function downloadUrl(dataUrl, filename) {
  // Construct the 'a' element
  var link = document.createElement("a");
  link.download = filename;
  link.target = "_blank";

  // Construct the URI
  link.href = dataUrl;
  document.body.appendChild(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
}

export function parseIntZero(value, base = 10) {
  const parsed = parseInt(value, base);
  if (isNaN(parsed)) { return 0; }
  return parsed;
}

export function calculateValueFromEvent(event) {
  return event.target.type === 'checkbox' ? event.target.checked : event.target.value
}