import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { TextField } from "components";
import BaseNumberFormat from "components/BaseNumberFormat";
// import omit from 'lodash/omit';

const NumberFormatRegular = ({ ...props }) => {
  const { amex, ...other } = props;
  const format = amex === true ? "#### ###### #####" : "#### #### #### ####";
  return (
    <BaseNumberFormat
      allowNegative={false}
      mask="_"
      format={format}
      {...other}
    />
  )
}

const LocalCreditCardField = (props) => {
  const { amex, InputProps, ...other } = props
  const creditCardProps = {
    amex: props.amex,
  }
  return (
    <TextField
      {...other}
      InputProps={{
        ...InputProps,
        inputProps: creditCardProps,
        inputComponent: NumberFormatRegular,
      }} />
  )
}

const CreditCardField = ({ innerDiv, ...rest }) => (
  <Fragment>
    {innerDiv ?
      <div className="mb-3">
        <LocalCreditCardField {...rest} />
      </div>
      :
      <LocalCreditCardField {...rest} />
    }
  </Fragment>
)

CreditCardField.propTypes = {
  inputProps: PropTypes.object,
  amex: PropTypes.bool,
};

CreditCardField.defaultProps = {
  inputProps: {},
  amex: false,
};

export default CreditCardField;