import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip, IconButton, Link } from '@material-ui/core';

const SocialMediaShortcuts = ({ ...props }) => {
  const { dark } = props;
  return (
    <Fragment>
      <div className="hero-footer pb-4">
        <Tooltip arrow title="Facebook">
          <IconButton
            component={Link}
            href="https://www.facebook.com/plataformaescolastia"
            target="_blank"
            color="inherit"
            size="medium"
            className="text-white-50">
            <FontAwesomeIcon
              icon={['fab', 'facebook']}
              className={clsx("font-size-xl", { "text-dark": dark })}
            />
          </IconButton>
        </Tooltip>
        {/* <Tooltip arrow title="Twitter">
          <IconButton
            color="inherit"
            size="medium"
            className="text-white-50">
            <FontAwesomeIcon
              icon={['fab', 'twitter']}
              className={clsx("font-size-xl", { "text-dark": dark })}
            />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Google">
          <IconButton
            color="inherit"
            size="medium"
            className="text-white-50">
            <FontAwesomeIcon
              icon={['fab', 'google']}
              className={clsx("font-size-xl", { "text-dark": dark })}
            />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Instagram">
          <IconButton
            color="inherit"
            size="medium"
            className="text-white-50">
            <FontAwesomeIcon
              icon={['fab', 'instagram']}
              className={clsx("font-size-xl", { "text-dark": dark })}
            />
          </IconButton>
        </Tooltip> */}
      </div>
    </Fragment>
  )
}

SocialMediaShortcuts.defaultProps = {
  dark: false
}

SocialMediaShortcuts.prototype = {
  dark: PropTypes.bool
}

export default SocialMediaShortcuts;