import React, { Fragment, useState, useEffect } from "react"

import Script from 'react-load-script'
import { useApi, obtenerOpenpaySettings } from '../../api'

const OpenpayScripts = (props) => {

  const [openpay, setOpenpay] = useState(false);
  const [antifraudes, setAntifraudes] = useState(false);
  const [openpaySettings, setOpenpaySettings] = useState(false);

  const [{ data: openpaySettingsData, loading: loadingOpenpaySettings }, openpaySettingsApi] = useApi();

  useEffect(() => {
    openpaySettingsApi(obtenerOpenpaySettings());
  }, []);

  useEffect(() => {
    if (openpaySettingsData) {
      setOpenpaySettings(openpaySettingsData);
      setOpenpay(true);
    }
  }, [openpaySettingsData]);

  const handleScriptLoaded = () => {
    if (window.OpenPay) {
      setAntifraudes(true);
    }
    if (window.OpenPay && window.OpenPay.deviceData) {
      setupOpenpay();
    }
  }

  const handleScriptError = () => {
    if (props.onError) {
      props.onError();
    }
  }

  const setupOpenpay = () => {
    window.OpenPay.setId(openpaySettingsData.merchantId);
    window.OpenPay.setApiKey(openpaySettingsData.publicKey);
    window.OpenPay.setSandboxMode(openpaySettingsData.sandbox);
    if (props.onReady) {
      const deviceSessionId = window.OpenPay.deviceData.setup();
      props.onReady(deviceSessionId, openpaySettings);
    }
  }

  return (
    <Fragment>
      {openpay === true &&
        <Script
          url="https://js.openpay.mx/openpay.v1.min.js"
          onError={handleScriptError}
          onLoad={handleScriptLoaded}
        />
      }
      {openpay === true && antifraudes === true &&
        <Script
          url="https://js.openpay.mx/openpay-data.v1.min.js"
          onError={handleScriptError}
          onLoad={handleScriptLoaded}
        />
      }
    </Fragment>
  )
}

export default OpenpayScripts;