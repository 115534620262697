
import React from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from "lodash";

// Material UI Components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogOkCancel = ({ ...props }) => {
  const { okLabel, okButton, cancelLabel } = props;

  // const renders = useRef(0);

  const hasCancel = !isNil(props.onCancel)
  let cancelLabelLocal = isEmpty(cancelLabel) ? "Cancelar" : cancelLabel;
  let okLabelLocal = isEmpty(okLabel) ? (!hasCancel ? "Entendido" : "Aceptar") : okLabel;

  const handleOnExit = () => {
    if (props.onExit) {
      props.onExit();
    }
  }

  return (
    <>
      {/* {console.log(renders.current++)} */}
      <Dialog
        open={props.open}
        onExit={handleOnExit}
        TransitionComponent={Transition}
        keepMounted={props.keepMounted}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div" id="alert-dialog-description">
            {props.msg}
          </DialogContentText>
          {props.children}
        </DialogContent>
        <DialogActions>
          {props.actions}
          {hasCancel &&
            <Button onClick={props.onCancel} color="primary">
              {cancelLabelLocal}
            </Button>
          }
          {!isNil(okButton) ?
            okButton
            :
            <Button onClick={props.onOk} color="primary">
              {okLabelLocal}
            </Button>
          }
        </DialogActions>
      </Dialog>
    </>
  )
};

DialogOkCancel.propTypes = {
  onCancel: PropTypes.func,
  onExit: PropTypes.func,
  onOk: PropTypes.func,
  open: PropTypes.bool,
  msg: PropTypes.node,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  okButton: PropTypes.node,
  title: PropTypes.string,
};

DialogOkCancel.defaultProps = {
  title: 'Atención',
  keepMounted: false,
}

export default React.memo(DialogOkCancel);