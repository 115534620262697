import React, { Fragment } from "react";
import { TextField } from "components";
import BaseNumberFormat from "components/BaseNumberFormat";

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function cardExpiry(val) {
  let month = limit(val.substring(0, 2), '12');
  let year = val.substring(2, 4);

  return month + (year.length ? '/' + year : '');
}

const NumberFormatRegular = (props) => {
  return (
    <BaseNumberFormat
      allowNegative={false}
      format={cardExpiry}
      placeholder="MM/YY"
      mask={['M', 'M', 'Y', 'Y']}
      {...props}
    />
  )
}

const LocalVencimientoCCField = (props) => {
  const { formater, ...other } = props;
  return (
    <TextField
      {...other}
      InputProps={{
        inputComponent: NumberFormatRegular,
        inputProps: { ...formater },
      }} />
  )
}

const VencimientoCCField = ({ innerDiv, ...rest }) => (
  <Fragment>
    {innerDiv ?
      <div className="mb-3">
        <LocalVencimientoCCField {...rest} />
      </div>
      :
      <LocalVencimientoCCField {...rest} />
    }
  </Fragment>
)

VencimientoCCField.propTypes = {
};

VencimientoCCField.defaultProps = {

};

export default VencimientoCCField;