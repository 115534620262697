import React, { Fragment } from 'react';

import PagesError404Content from './PagesError404';

export default function PagesError404() {
  return (
    <Fragment>
      <PagesError404Content />
    </Fragment>
  );
}
