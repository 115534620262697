import React, { useState, useEffect, Fragment } from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';

import Snackbar from 'components/Snackbar';


const appInitialState = {
  scrolling: false,
  needUpdateSession: false,
}

const appContextInitialState = {
  app: {},
  notificacion: {},
  notificaciones: [],
  notificar: () => { },
  notificarInfo: () => { },
  notificarError: () => { },
  notificarWarn: () => { },
  actualizaApp: () => { },
};

export const AppContext = React.createContext(appContextInitialState);

export const useApp = () => {
  const contextState = React.useContext(AppContext);
  if (contextState === null) {
    throw new Error('useApp must be used within a AppDesktop tag');
  }
  return contextState;
};

const AppDesktop = ({ children, ...props }) => {

  // const renders = useRef(0);

  const [app, setApp] = useState(appInitialState);
  const [notificacion, setNotificacion] = useState(null);
  const [notificaciones, setNotificaciones] = useState([]);

  useEffect(() => {
    //Ajustes para moment GLOBAL...
    moment.locale('es', { week: { dow: 0 } }) //La semana va de dom-sab
  }, [])

  //Funciones para el AppContext
  const notificar = React.useCallback((notificacion, tipo) => {
    buildNotificacion(notificacion, tipo)
  }, []);

  const notificarError = React.useCallback((notificacion) => {
    buildNotificacion(notificacion, 'error')
  }, []);

  // useEffect(() => {
  //   console.log("notificarError", "cambio!")
  // }, [notificarError]);

  const notificarInfo = React.useCallback((notificacion) => {
    buildNotificacion(notificacion, 'info')
  }, []);

  const notificarWarn = React.useCallback((notificacion) => {
    buildNotificacion(notificacion, 'warning')
  }, []);

  const actualizaApp = React.useCallback((value) => {
    if (value === undefined) {
      setApp(appInitialState);
    } else {
      setApp({
        ...app,
        ...value,
      });
    }
  }, []);

  //Funciones Locales...
  const buildNotificacion = (_notificacion, tipo) => {
    if (typeof _notificacion === 'string') {
      _notificacion = {
        id: notificaciones.length,
        mensaje: _notificacion,
      }
    }
    if (tipo) {
      _notificacion = {
        ..._notificacion,
        tipo,
      }
    }
    setNotificaciones([...notificaciones, _notificacion]);
    setNotificacion(notificacion || _notificacion);
  }

  const handleSnackbarDismiss = (id) => {
    //TODO: Si se van acumulando mensajes... Se muestran??
    setNotificaciones(notificaciones.filter(r => r.id !== id) || []);
    setNotificacion(null);
  }

  return (
    <Fragment>
      {/* {console.log("AppDesktop", renders.current++)} */}
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
        <AppContext.Provider value={{
          app,
          notificacion,
          notificaciones,
          notificar,
          notificarInfo,
          notificarError,
          notificarWarn,
          actualizaApp,
        }}>
          <Fragment>
            {children}
            {notificacion &&
              <Snackbar
                key={notificacion.id}
                notificacion={notificacion}
                onDismiss={handleSnackbarDismiss} />
            }
          </Fragment>
        </AppContext.Provider>
      </MuiPickersUtilsProvider>
    </Fragment>
  )
}

export default React.memo(AppDesktop);