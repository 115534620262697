import React, { lazy, Suspense, Fragment, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { PulseLoader } from 'react-spinners';

import MuiTheme from './theme';

import CuentaProvider, { useCuenta } from 'components/CuentaProvider/CuentaProvider';
import ReactGA from "react-ga4";
// Layout Blueprints

import {
  LeftSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

// Example Pages

import PagesLogin from './pages/PagesLogin';
import PagesError404 from './pages/PagesError404';

// import PagesOrganizacion from './pages/PagesOrganizacion';

const PagesRegister = lazy(() => import('./pages/PagesRegister'));
const PagesRecoverPassword = lazy(() => import('./pages/PagesRecoverPassword'));
const PagesChangePasswordFromRecover = lazy(() => import('./pages/PagesChangePasswordFromRecover'));
const PagesConfirmarCuenta = lazy(() => import('./pages/PagesConfirmarCuenta'));
const PagesConfirmarCambioCorreo = lazy(() => import('./pages/PagesConfirmarCambioCorreo'));
const PagesConfiguraInstancia = lazy(() => import('./pages/PagesConfiguraInstancia'));
const PagesCreandoInstancia = lazy(() => import('./pages/PagesCreandoInstancia'));
const PagesProfile = lazy(() => import('./pages/PagesProfile'));
const PagesOrganizacion = lazy(() => import('./pages/PagesOrganizacion'));
const PagesProductos = lazy(() => import('./pages/PagesProductos'));
const PagesEstadoCuenta = lazy(() => import('./pages/PagesEstadoCuenta'));
const PagesCobrosElectronicos = lazy(() => import('./pages/PagesCobrosElectronicos'));

const PagesTutoresSoporte = lazy(() => import('./pages/PagesTutoresSoporte'));
const PagesLegales = lazy(() => import('./pages/PagesContenidos/PagesLegales'));
const PagesFormasPago = lazy(() => import('./pages/PagesContenidos/PagesFormasPago'));

const PageDashboard = lazy(() => import('./pages/PageDashboard'));

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.99
  },
  in: {
    opacity: 1,
    scale: 1
  },
  out: {
    opacity: 0,
    scale: 1.01
  }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.4
};

export const SuspenseLoading = () => {
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <PulseLoader color={'#5383ff'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Un momento por favor, estamos cargando los componentes
          {/* <span className="font-size-lg d-block text-dark">
            This live preview instance can be slower than a real production
            build!
          </span> */}
        </div>
      </div>
    </Fragment>
  );
};

const AccountPaths = [
  '/dashboard',
  '/micuenta',
  '/organizacion',
  '/productos',
  '/edocuenta',
  '/cobroselectronicos'
];

const SecureRoutes = () => {
  const { cuenta } = useCuenta();
  const location = useLocation();

  return (
    <Switch>
      {cuenta.init === true && cuenta.loaded === true && cuenta.logged === true &&
        <Fragment>
          {/* <Redirect to="/dashboard" /> */}
          {!cuenta.cliente &&
            <Redirect to="/configurainstancia" />
          }

          {cuenta.cliente && (!cuenta.cliente.instancia || (cuenta.cliente.instancia && cuenta.cliente.instancia.estatus !== 'LISTA')) &&
            <Redirect to="/creandoinstancia" />
          }

          <Route
            path={[
              '/configurainstancia',
              '/creandoinstancia'
            ]}>
            <MinimalLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/configurainstancia" component={PagesConfiguraInstancia} />
                  <Route path="/creandoinstancia" component={PagesCreandoInstancia} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>

          <Route
            path={[
              ...AccountPaths
            ]}>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/dashboard" component={PageDashboard} />
                  <Route path="/micuenta" component={PagesProfile} />
                  <Route path="/organizacion" component={PagesOrganizacion} />
                  <Route path="/productos" component={PagesProductos} />
                  <Route path="/edocuenta" component={PagesEstadoCuenta} />
                  <Route path="/cobroselectronicos" component={PagesCobrosElectronicos} />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>
        </Fragment>
      }
      {cuenta.init === true && cuenta.logged === false &&
        <Fragment>
          <Redirect to="/login" />
          <Route
            path={[
              '/login',
            ]}>
            <MinimalLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/login" component={PagesLogin} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>
        </Fragment>
      }
    </Switch>
  )
};

const CuentaRoutes = () => {
  return (
    <CuentaProvider>
      <SecureRoutes />
    </CuentaProvider>
  )
}

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    //ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route
              path={[
                '/registrar',
                '/restablecepassword',
                '/nuevopassword',
                '/confirmar',
                '/confirmaremail',
                '/tutores/soporte',
                '/privacidad',
                '/terminos',
                '/clientes/formaspago/tdc',
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/registrar" component={PagesRegister} />
                    <Route path="/restablecepassword" component={PagesRecoverPassword} />
                    <Route path="/nuevopassword" component={PagesChangePasswordFromRecover} />
                    <Route path="/confirmar" component={PagesConfirmarCuenta} />
                    <Route path="/confirmaremail" component={PagesConfirmarCambioCorreo} />

                    <Route path="/tutores/soporte" component={PagesTutoresSoporte} />
                    <Route path="/privacidad" render={(props) => <PagesLegales {...props} privacidad />} />
                    <Route path="/terminos" render={(props) => <PagesLegales {...props} terminos />} />
                    <Route path="/clientes/formaspago/tdc" render={(props) => <PagesFormasPago {...props} />} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route
              path={[
                ...AccountPaths,
                '/login',
                '/configurainstancia',
                '/creandoinstancia',
              ]}>
              <CuentaRoutes />
            </Route>
            <Route component={PagesError404} />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;