import { useEffect, useRef, useState, useCallback } from 'react';
import { isNil } from "lodash";

// Hook
export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

// Hook
export const useLocalStorage = (key, initialValue) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.error(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return [storedValue, setValue];
}

// Hook
// export const useUnmounted = () => {
//     const unmounted = useRef(false)
//     useEffect(() => () => {
//         unmounted.current = true
//     }, [])
//     return unmounted
// }

export const useIsMounted = () => {
    const isMounted = useRef(false)
    useEffect(() => {
        isMounted.current = true
        return function cleanup() {
            isMounted.current = false
        }
    }, [])
    const checker = useCallback(() => {
        return isMounted.current
    }, [])
    return checker
}

export const useCancel = (source) => {
    useEffect(() => {
        return () => {
            if (source) {
                source.cancel();
            }
        };
    }, [source]);
}

export default function useExternalScripts({ url, id }) {
    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");

        if (!isNil(id)) {
            script.setAttribute("id", id);
        }
        script.setAttribute("src", url);
        head.appendChild(script);

        return () => {
            head.removeChild(script);
        };
    }, [url]);
};