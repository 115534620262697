export const obtenerAsentamientos = (cp) => ({
  url: `asentamientos?cp=${cp}`,
  method: 'GET',
});

export const obtenerBancos = (todos = false) => ({
  url: `bancos`,
  method: 'GET',
  params: {
    todos,
  }
});

export const obtenerOpenpaySettings = () => ({
  url: `openpay`,
  method: 'GET',
});