import React from "react";
// import PropTypes from "prop-types";
import { default as MaterialTextField } from "@material-ui/core/TextField";
import isEmpty from "lodash/isEmpty";
import { Fragment } from "react";
import clsx from "clsx";

const LocalTextField = ({ id, field, errors, helperText, name, ...rest }) => (
  <MaterialTextField
    {...rest}
    id={id}
    error={!isEmpty(errors) && !isEmpty(errors[id || field])}
    name={name || field}
    helperText={(!isEmpty(errors) && errors[id || field]) || helperText}
  />
);

const TextField = ({ innerDiv, innerDivClassName, ...rest }) => (
  <Fragment>
    {innerDiv ?
      <div className={clsx("mb-3", innerDivClassName)}>
        <LocalTextField {...rest} />
      </div>
      :
      <LocalTextField {...rest} />
    }
  </Fragment>
)

TextField.defaultProps = {
  variant: "outlined",
  innerDiv: true
};

TextField.propTypes = {

};

export default TextField;