import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import LoadingPanel from 'components/LoadingPanel';
import { withStyles } from '@material-ui/core';

const DialogContent = withStyles({
  root: {
    padding: '16px 16px',
  },
})(MuiDialogContent);

const LoadingDialog = ({ ...props }) => {
  const { msg, open, onExited } = props;
  // const renders = useRef(0);

  return (
    <>
      {/* {console.log(renders.current++)} */}
      <Dialog
        open={open}
        onExited={onExited}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent style={{ overflow: 'hidden' }}>
          <LoadingPanel msg={msg} />
        </DialogContent>
      </Dialog>
    </>
  )

}

LoadingDialog.propTypes = {
  msg: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onExited: PropTypes.func,
};

export default React.memo(LoadingDialog);