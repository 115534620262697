import React from "react";
import NumberFormat from 'react-number-format';
import isNumber from 'lodash/isNumber';

const BaseNumberFormat = (props) => {
  let rawValue = props.value;
  const { inputRef, onChange, onBlur, ...other } = props;

  const convertoToNumber = (value, decimalScale) => {
    return value ? decimalScale ? parseFloat(value) : parseInt(value, 10) : '';
  }

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        //Esto se tiene que hacer ya que el valor inicial no necesariamente es un número, 
        //puede ser un texto, entonces evaluamos manzanas con manzanas
        const realValue = convertoToNumber(values.value, other.decimalScale);
        const _rawValue = isNumber(rawValue) ? rawValue : convertoToNumber(rawValue, other.decimalScale);
        if (realValue !== _rawValue) { //TODO: Revisar, -- al 19 de marzo 2021 no se si es válido --, ya que aún que no generar el error, tiene un comportamiento extraño
          rawValue = values.value;
          onChange({
            target: {
              value: values.value
            }
          });
        }
      }}
      onBlur={() => {
        onBlur({
          target: {
            value: rawValue
          }
        });
      }}
      {...other}
    />
  )
}

export default BaseNumberFormat;