import React, { Fragment } from 'react';
import clsx from 'clsx';
import isUndefined from 'lodash/isUndefined';

import {
  Paper,
  Box,
  Button,
} from '@material-ui/core';

import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import { connect } from 'react-redux';

function PageTitle(props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleIconBox,
    pageTitleDescription,
  } = props;

  return (
    <Fragment>
      <Paper
        square
        elevation={2}
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground)}>
        <div>
          {/* {pageTitleBreadcrumb && (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              className="mb-4"
              maxItems={2}
              aria-label="breadcrumb">
              <Link color="inherit" to="#" onClick={e => e.preventDefault()}>
                Home
              </Link>
              <Link color="inherit" to="#" onClick={e => e.preventDefault()}>
                Dashboards
              </Link>
              <Link color="inherit" to="#" onClick={e => e.preventDefault()}>
                Examples
              </Link>
              <Link color="inherit" to="#" onClick={e => e.preventDefault()}>
                Pages
              </Link>
              <Typography color="textPrimary">{props.titleHeading}</Typography>
            </Breadcrumbs>
          )} */}

          <Box className="app-page-title--first">
            {pageTitleIconBox && (
              <Paper
                elevation={2}
                className="app-page-title--iconbox d-70 d-flex align-items-center bg-secondary justify-content-center">
                <DashboardTwoToneIcon />
              </Paper>
            )}
            <div className="app-page-title--heading">
              <h4>{props.titleHeading}</h4>
              {pageTitleDescription && !isUndefined(props.titleDescription) && props.titleDescription !== '' && (
                <div className="app-page-title--description">
                  {props.titleDescription}
                </div>
              )}
            </div>
          </Box>
        </div>
        {props.actions &&
          <div className="d-flex align-items-center">
            {props.actions}
          </div>
        }
      </Paper>
    </Fragment>
  );
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);
