import React, { useState, Fragment, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Avatar,
  Box,
  Menu,
  Button,
  List,
  ListItem,
  Divider
} from '@material-ui/core';

import avatarEmpty from '../../assets/images/avatars/avatar-empty.jpg';
import { useCuenta } from 'components/CuentaProvider';
import { useApi } from 'api';
import { DialogOkCancel, LoadingDialog } from 'components';
import { logout } from 'api/Cuenta';
import { useHistory, useLocation } from 'react-router';

export default function HeaderUserbox() {

  const location = useLocation();
  const history = useHistory();
  const { cuenta, actualizaCuenta } = useCuenta();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDialogCerrarSesion, setShowDialogCerrarSesion] = useState(false);

  const [{ data: cuentaData, loading: loadingCuenta }, cuentaApi] = useApi();

  useEffect(() => {
    if (cuentaData) {
      actualizaCuenta();
      history.replace("/dashboard");
    }
  }, [cuentaData]);

  const handleClickMiCuenta = () => {
    setAnchorEl(null);
    if (location.pathname !== '/micuenta') {
      history.push("/micuenta")
    }
  }

  const handleClickLogout = () => {
    setShowDialogCerrarSesion(true);
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionCerrarSesionDialog = action => event => {
    setShowDialogCerrarSesion(false);
    if (action === 'ok') {
      cuentaApi(logout());
    }
  }

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          {/* <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            variant="dot"> */}
          <Avatar sizes="44" alt={cuenta.nombreCompleto} src={avatarEmpty} />
          {/* </StyledBadge> */}
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-0 line-height-1">
            {`${cuenta.nombre} ${cuenta.apellido1}`}
          </div>
          {/* <span className="text-white-50">Senior React Developer</span> */}
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              {/* <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                variant="dot"> */}
              <Avatar sizes="44" alt={cuenta.nombreCompleto} src={avatarEmpty} />
              {/* </StyledBadge> */}
            </Box>
            <div className="pl-3 ">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {cuenta.nombreCompleto}
              </div>
              {/* <span className="text-black-50 text-center">
                Senior React Developer
              </span> */}
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem
              onClick={handleClickMiCuenta}
              button>Mi cuenta</ListItem>
            <ListItem
              button
              onClick={handleClickLogout}>Cerrar sesion</ListItem>
            <Divider className="w-100" />
          </List>
        </div>
      </Menu>

      <DialogOkCancel
        open={showDialogCerrarSesion}
        msg={"¿Confirmas cerrar tu sesión?"}
        onCancel={(handleActionCerrarSesionDialog('cancel'))}
        onOk={(handleActionCerrarSesionDialog('ok'))} />

      <LoadingDialog open={loadingCuenta} msg="Cerrando tu sesión..." />
    </Fragment>
  );
}
